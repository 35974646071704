html,
body,
#root {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tree-view-container {
  font-family: Arial, sans-serif;
  padding: 16px;
}

.tree-view-search {
  margin-bottom: 16px;
  position: relative;
}

.tree-view-search-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.tree-view-alert {
  background-color: #ffebee;
  border: 1px solid #ffcdd2;
  color: #b71c1c;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.tree-view-list {
  list-style-type: none;
  padding-left: 16px;
}

.tree-view-item {
  margin: 4px 0;
}

.tree-view-item-content {
  display: flex;
  align-items: center;
}

.tree-view-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 4px;
  display: flex;
  align-items: center;
}

.tree-view-icon {
  margin: 5px 10px;
  font-size: 15px !important;
}

.tree-view-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  text-transform: capitalize;
}

.tree-view-label:hover {
  color: #2196f3;
}

/* Adjust icon colors */
.tree-view-icon {
  color: #0c2e69;
}

.tree-view-toggle svg {
  color: #000000;
}

.tree-view-value {
  color: #0a3069;
  background-color: #f1f8ff;
  padding: 1px 4px;
  border-radius: 2px;
  font-family: monospace;
  max-width: 290px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-help {
  font-size: 12px;
  color: #6e6e6e;
}
